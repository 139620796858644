<template>
  <section v-if="data" class="edit">
    <form @submit.prevent="submitForm">

      <h3>Informations du site</h3>
      <section v-if="data.titles && data.aPropos && data.colors" class="infos">
        <div>
          <div>
            <label for="title1">Titre 1 :</label>
            <input type="text" id="title1" class="inputText" v-model="data.titles.H1" required/>
          </div>
          <div>
            <label for="title2">Titre 2 :</label>
            <input type="text" id="title2" class="inputText" v-model="data.titles.H2" required/>
          </div>

          <div>
            <label for="color1">Couleur textes titre :</label>
            <input type="color" id="color1" class="inputColor" v-model="data.colors.titleColor" />
          </div>
          <div>
            <label for="color2">Couleur footer :</label>
            <input type="color" id="color2" class="inputColor" v-model="data.colors.footerColor" />
          </div>
          <div>
            <label for="title1">Image du haut:</label>
            <input type="text" id="image" class="inputText" v-model="data.titles.img" />
          </div>
          <div>
            <label for="title1">Image page à propos:</label>
            <input type="text" id="image" class="inputText" v-model="data.aPropos.img" />
          </div>
          <div>
            <label for="title1">Compte instagram :</label>
            <input type="text" id="title1" class="inputText" v-model="data.aPropos.instagram" />
          </div>
          <div>
            <label for="title2">Mail :</label>
            <input type="text" id="title2" class="inputText" v-model="data.aPropos.mail" />
          </div>
        </div>
        <div class="textarea">
          <label for="text">Texte à propos :</label>
          <textarea id="text" v-model="data.aPropos.text" required></textarea>
        </div>
      </section>

      <h3>Ajouter ou supprimer des photos en Modèle</h3>
      <section v-if="data.photos" class="allPhotos">
        <div v-for="(photo, index) in data.photos['modele']" :key="index" class="onePhoto">
          <div>
            <label for="titlePhoto">Titre :</label>
            <input type="text" id="titlePhoto" class="inputText" v-model="photo.name" required />
          </div>
          <div>
            <label for="datePhoto">Date :</label>
            <input type="date" id="datePhoto" class="inputText" v-model="photo.date" required/>
          </div>
          <div>
            <label for="urlPhoto">Nom du fichier :</label>
            <input type="text" id="urlPhoto" class="inputText" v-model="photo.img" required/>
          </div>
          <!-- <img :src="photo.img"> -->
          <img src="../img/trash.svg" @click="deletePhoto(index, 'modele')" class="trash">
        </div>
        <img src="../img/add.svg" @click="addPhoto('modele')" class="add">
      </section>


      <h3>Ajouter ou supprimer des photos en Photographe</h3>
      <section v-if="data.photos" class="allPhotos">
        <div v-for="(photo, index) in data.photos['photographe']" :key="index" class="onePhoto">
          <div>
            <label for="titlePhoto">Titre :</label>
            <input type="text" id="titlePhoto" class="inputText" v-model="photo.name" required/>
          </div>
          <div>
            <label for="datePhoto">Date :</label>
            <input type="date" id="datePhoto" class="inputText" v-model="photo.date" required />
          </div>
          <div>
            <label for="urlPhoto">Nom du fichier :</label>
            <input type="text" id="urlPhoto" class="inputText" v-model="photo.img" required/>
          </div>
          <!-- <img :src="photo.img"> -->
          <img src="../img/trash.svg" @click="deletePhoto(index, 'photographe')" class="trash">
        </div>
        <img src="../img/add.svg" @click="addPhoto('photographe')" class="add">
      </section>

      <button type="submit">Valider</button>
    </form>
  </section>
</template>

<script>
import { getData } from '@/api/getData.js'

export default {
  name: "EditJSON",
  data() {
    return {
      data: [],
    };
  },
  methods: {
    submitForm() {
      const jsonData = JSON.stringify(this.data);
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'data.json';
      link.click();

      URL.revokeObjectURL(url);
      link.remove();
    },
    async retrieveData() {
      this.data = await getData()
    },
    addPhoto(type) {
      const newPhoto = {
        name: '',
        date: '',
        img: ''
      };
      this.data.photos[type].push(newPhoto);
    },
    deletePhoto(index, type) {
      const photo = this.data.photos[type][index];
      const confirmDelete = confirm(`Êtes-vous sûr de vouloir supprimer la photo "${photo.name}" ?`);
      if (confirmDelete) {
        this.data.photos[type].splice(index, 1);
      }
    },
  },
  created() {
    this.retrieveData()
  }
};
</script>

  
<style scoped>
.edit {
  padding: 2rem 10rem;
  font-size: 1.5rem;
}

h3 {
  padding: 2rem 0;
  font-size: 2.5rem;
}

form div {
  margin: 1rem 0;
}

label {
  margin-right: 1rem;
}

.inputText {
  width: 15vw;
  height: 2rem;
}

textarea {
  width: 30vw;
  height: 20rem;
}

.textarea {
  display: flex;
  flex-direction: column;
}

.infos {
  background-color: #e0e0e0;
  padding: 2rem;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: row;
}

.infos>div {
  width: 40%;
}

.allPhotos {
  display: flex;
  flex-wrap: wrap;
}

.onePhoto {
  background-color: #e0e0e0;
  margin: 1rem 2rem;
  padding: 2rem;
  width: 30vw;
  position: relative;
}

.preview {
  width: 10%;
}

.add {
  margin: 0 2rem;
  width: 4rem;
  cursor: pointer;
}

.trash {
  width: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}

button {
  margin: 2rem 10%;
  width: 10rem;
  height: 3rem;
  border: none;
}

@media screen and (max-width: 1024px) {
  .infos {
    flex-direction: column;
  }

  .infos div {
    width: 100%;
  }

  textarea {
    width: 80vw;
  }

  .edit {
    padding: 2rem 1rem;
  }

  .onePhoto {
    width: 80vw;
  }
}

@media screen and (max-width: 768px) {
  .infos {
    flex-direction: column;
    margin: 0.5rem;
    padding: 1rem;
  }

  .infos div {
    width: 100%;
  }

  .inputText {
    width: 15rem;
  }

  textarea {
    width: 15rem;
  }

  .edit {
    padding: 2rem;
  }

  .allPhoto {
    margin: 0.5rem;
    padding: 1rem;
  }

  .onePhoto {
    width: 80vw;
    padding: 1rem;
    margin: 1rem 0;
  }

  .add {
    width: 3rem;
  }
}
</style>