<template>
  <div class="portfolio">
    <GalleryPhotos v-if="activPage==='Modèle'" :typePhoto="'Modèle'"></GalleryPhotos>
    <GalleryPhotos v-if="activPage==='Photographe'" :typePhoto="'Photographe'"></GalleryPhotos>
    <APropos v-if="activPage==='A Propos'"></APropos>
    <Mentions v-if="activPage==='Mentions'"></Mentions>
  </div>
</template>

<script>
import APropos from '@/components/APropos.vue'
import GalleryPhotos from '@/components/GalleryPhotos.vue'
import Mentions from '@/components/Mentions.vue'

export default {
  name: 'PortfolioPages',
  props: ['activPage', 'pages'],
  components: {
    GalleryPhotos,
    APropos, 
    Mentions
  },
  data() {
    return {}
  }
}
</script>

<style>
@media screen and (max-width: 1024px) {}
@media screen and (max-width: 768px) {}
</style>
