<template>
  <div class="app">
    <a href="#top" class="fleche">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
        <path
          d="M27.414 12.586l-10-10c-0.781-0.781-2.047-0.781-2.828 0l-10 10c-0.781 0.781-0.781 2.047 0 2.828s2.047 0.781 2.828 0l6.586-6.586v19.172c0 1.105 0.895 2 2 2s2-0.895 2-2v-19.172l6.586 6.586c0.39 0.39 0.902 0.586 1.414 0.586s1.024-0.195 1.414-0.586c0.781-0.781 0.781-2.047 0-2.828z">
        </path>
      </svg>
    </a>

    <Navbar v-model:activPage="activPage" :pages="pages"></Navbar>
    <Header :titleColor="colors.titleColor"></Header>
    <router-view :activPage="activPage" :pages="pages"></router-view>
    <Footer :colors="colors" v-model:activPage="activPage"></Footer>
  </div>
</template>

<script>
import { getColors } from '@/api/getData.js'
import Header from '@/components/Header.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Navbar,
    Footer
  },
  data() {
    return {
      pages: ["Modèle", "Photographe", "A Propos"],
      activPage: "Modèle",
      colors: []
    }
  },
  methods: {
    async retrieveData() {
      this.colors = await getColors()
    }
  },
  beforeMount() {
    this.retrieveData()
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  scroll-behavior: smooth;
}

.app {
  background-color: white;
  color: black;
}

.button {
  cursor: pointer;
}

.fleche {
  fill: black;
  position: fixed;
  right: 3%;
  bottom: 4%;
  transition: 0.1s ease-in;
  z-index: 10;
  width: 4rem;
}

.fleche:hover {
  transform: scale(1.1);
}

.icon {
  width: 4rem;
  padding: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}</style>
