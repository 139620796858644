<template>
  <div class="cardPhoto" :style="{ backgroundImage: 'url(./img/' + data.img + ')' }">
    <div class="content">
      <h3>{{ data.name }}</h3>
      <p>{{ data.date }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardPhoto',
  components: {},
  props: {
    data: { type: Object, required: true },
  },
  data() {
    return {
      showFullImage: false,
    };
  },
  methods: {
    showImage() {
      this.showFullImage = true;
    },
    hideImage() {
      this.showFullImage = false;
    },
  },
}
</script>
  
<style scoped>
.cardPhoto {
  background-color: black;
  color: white;
  /* height: 30vh;
  width: 30vh; */
  min-height: 400px;
  background-size: cover;
  background-position: 50% 50%;
  margin: 1rem;
  position: relative;
  transition: filter 0.3s;
  cursor: pointer
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.cardPhoto:hover {
  filter: brightness(70%);
  /* Assombrissement du fond */
}

.cardPhoto:hover .content {
  opacity: 1;
}

.span-2 {
  grid-column-end: span 2;
  grid-row-end: span 2;
  min-height: 400px;
}

.span-3 {
  grid-column-end: span 3;
  grid-row-end: span 3;
  min-height: 400px;
}

@media screen and (max-width: 1024px) {
  .cardPhoto{
    min-height: 200px;
    min-width: 200px;
    max-width: 75vw;
  }
}

@media screen and (max-width: 768px) {}</style>
  