<template>
  <div>
    <section class="grid">
      <CardPhoto v-for="photo in photos" :key="photo.id" :data="photo" @click="openFullscreen(photo)"></CardPhoto>
    </section>

    <div v-if="selectedPhoto.fullscreen" class="fullscreen-overlay">
      <div class="fullscreen-container">
        <img @click="previousPhoto" src="../img/left.svg" class="fullscreen-left icon">
        <img :src="'./img/'+selectedPhoto.photo.img" alt="Fullscreen Photo" class="fullscreen-image">
        <img @click="closeFullscreen" src="../img/close.svg" class="fullscreen-close icon">
        <img @click="nextPhoto" src="../img/right.svg" class="fullscreen-right icon">
      </div>
    </div>
  </div>
</template>

<script>
import { getPhotosModele, getPhotosPhotographe } from '@/api/getData.js'
import CardPhoto from '@/components/CardPhoto.vue'

export default {
  name: 'GalleryPhotos',
  components: {
    CardPhoto,
  },
  props: {
    typePhoto: { type: String, required: true },
  },
  data() {
    return {
      photos: [],
      selectedPhoto: {
        photo: null,
        fullscreen: false,
        currentIndex: 0
      },
    }
  },
  methods: {
    async retrieveData() {
      if(this.typePhoto === "Modèle"){
        this.photos = await getPhotosModele()
      }
      else {
        this.photos = await getPhotosPhotographe()
      }
    },
    openFullscreen(photo) {
      this.selectedPhoto.photo = photo;
      this.selectedPhoto.fullscreen = true;
    },
    closeFullscreen() {
      this.selectedPhoto.fullscreen = false;
    },
    nextPhoto() {
      this.selectedPhoto.currentIndex = (this.selectedPhoto.currentIndex + 1) % this.photos.length;
      this.selectedPhoto.photo = this.photos[this.selectedPhoto.currentIndex];
    },
    previousPhoto() {
      this.selectedPhoto.currentIndex = (this.selectedPhoto.currentIndex - 1 + this.photos.length) % this.photos.length;
      this.selectedPhoto.photo = this.photos[this.selectedPhoto.currentIndex];
    }
  },
  created() {
    this.retrieveData()
  }
}
</script>

  
<style scoped>
html {
  background: black;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 2px;
  grid-auto-rows: min-max(80px, auto);
  grid-auto-flow: dense;
  padding: 8rem;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-container {
  position: relative;
  display: flex;
  align-items: center;
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
}

.icon {
  width: 3rem;
}

.fullscreen-close {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .grid {
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {}</style>