<template>
  <footer :style="{ backgroundColor: colors.footerColor }">
    <p :style="{ color: colors.titleColor }">{{ textFooter }} | <span v-on:click="() => goMentions()">Mentions légales</span>
    </p>
  </footer>
</template>


<script>
import { getFooter } from '@/api/getData.js'
export default {
  name: 'FooterPage',
  props: {
    activPage: { type: String, required: true },
    colors: { type: Object, required: true },
  },
  emits: ["update:activPage"],
  data() {
    return {
      textFooter: "",
    }
  },
  methods: {
    async retrieveData() {
      this.textFooter = await getFooter()
    },
    goMentions() {
      this.$emit("update:activPage", "Mentions")
    }
  },
  beforeMount() {
    this.retrieveData()
  }
}
</script>
  

<style scoped>
footer {
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
span{
  cursor: pointer;
}
@media screen and (max-width: 1024px) {}

@media screen and (max-width: 768px) {}
</style>