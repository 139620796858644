const getData = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data
    } else {
        new Error(response.statusText)
    }
} 

const getAPropos = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.aPropos
    } else {
        new Error(response.statusText)
    }
} 

const getFooter = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.footer
    } else {
        new Error(response.statusText)
    }
} 

const getTitles = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.titles
    } else {
        new Error(response.statusText)
    }
} 

const getColors = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.colors
    } else {
        new Error(response.statusText)
    }
}

const getPhotosModele = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.photos["modele"]
    } else {
        new Error(response.statusText)
    }
}
const getPhotosPhotographe = async function() {
    const response = await fetch("./data.json")
    if (response.status == 200) {
        let data = await response.json()
        return data.photos["photographe"]
    } else {
        new Error(response.statusText)
    }
}


export { getData, getAPropos, getColors, getTitles, getPhotosModele, getPhotosPhotographe, getFooter}
