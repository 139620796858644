<template>
  <section class="APropos">
    <div class="photo"><img :src="'./img/'+data.img" alt="Photo de Nelly"></div>
    <div class="text">
        <h3>{{data.title}}</h3>
        <p class="des">{{data.text}}</p>
        <div class="network">
            <div>
                <a href="https://www.instagram.com/nellyavec2ailes__/?hl=fr" targe="_blank">
                    <img class="icon" src="../img/instagram.svg">
                </a>
                <p>{{data.instagram}}</p>
            </div>
            <div>
                <a href="mailto:nellygouzien@hotmail.fr" targe="_blank">
                    <img class="icon" src="../img/mail.svg">
                </a>
                <p>{{data.mail}}</p>
            </div>
        </div>
    </div>
  </section>
</template>


<script>
import { getAPropos } from '@/api/getData.js'

export default {
  name: 'APropos', 
  data() {
    return {
      data: [],
    }
  },
  methods: {
    async retrieveData(){
      this.data = await getAPropos()
    }
  },
  beforeMount() {
    this.retrieveData()
  }
}
</script>
  

<style scoped>
.APropos{
    display: flex;
    flex-direction: row;
    padding: 10rem;
    font-size:1.5rem;
}
.photo{
    width: 35%;
}
.photo img{
    width: 100%;
}
.text{
    width: 65%;
    padding: 4rem;
}
.text .des{
    margin-bottom: 2rem;
}
.text h3{
    font-size:3rem;
    margin-bottom: 1rem;
}
.network{
    display: flex;
    flex-direction: column;
}
.network div{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
@media screen and (max-width: 1024px) {
.APropos{
    flex-direction: column;
    padding: 10rem 2rem;
}
.APropos > *{
    width: 100%;
}
.text{
    padding: 4rem 0;
    font-size: 1.2rem;
}
}
@media screen and (max-width: 768px) {
}
</style>