<template>
  <header v-if="titles.img" :style="{ background: 'url(./img/' + titles.img + ')' }">
    <h1 :style="{ color: titleColor }">{{ titles.H1 }}</h1>
    <h2 :style="{ color: titleColor }">{{ titles.H2 }}</h2>
  </header>
</template>

<script>
import { getTitles } from '@/api/getData.js'
export default {
  name: 'HeaderPage',
  props: {
    titleColor: { type: String, required: true }
  },
  data() {
    return {
      titles: []
    }
  },
  methods: {
    async retrieveData() {
      this.titles = await getTitles()
    }
  },
  beforeMount() {
    this.retrieveData()
  }
}
</script>


<style scoped>
header {
  background-position: center;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
}

h1 {
  /* font-family: 'Lilita One', cursive; */
  font-size: 6rem;
}

h2 {
  font-size: 3rem;
}

p {
  font-size: 3rem;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {}</style>
  